export default ($) => ({
  floatingMenu(){
    var header = $("header"),
          fnc = {},
          ww = $(window).width();
          
    fnc.runfnc = function () {
        var topmin = 0;
        if($(window).scrollTop() > topmin){
          if(!header.hasClass("float")){
              header.addClass("float");
          }
        }else{
            if(header.hasClass("float")){
                header.removeClass("float");
            }
        }
    } 
    $(window).scroll(function () {
        fnc.runfnc();
    })
    fnc.runfnc();
  },
  toggleMenu() {
    const body = $("body");
    const offcanvas = $(".offcanvas");
    const offcanvasOverlay = $(".offcanvas-overlay");
    const offcanvasBody = $(".offcanvas-body");
    const navToggler = $(".nav-toggler");
    const menu = $(".navbar-nav .nav-item a");

    navToggler.on("click", function () {
      if (offcanvas.hasClass("show")) {
        body.removeAttr("style");
        offcanvas.removeClass("show");
        offcanvasOverlay.fadeOut(300);
        offcanvasBody.fadeOut(150);
      } else {
        body.css({ overflowY: "hidden" });
        offcanvasBody.fadeIn(150);
        offcanvas.addClass("show");
        offcanvasOverlay.fadeIn(300);
      }
    });

    offcanvasOverlay.on("click", function () {
      if (offcanvas.hasClass("show")) {
        body.removeAttr("style");
        offcanvas.removeClass("show");
        offcanvasOverlay.fadeOut(300);
        offcanvasBody.fadeOut(150);
      } else {
        body.css({ overflowY: "hidden" });
        offcanvasBody.fadeIn(150);
        offcanvas.addClass("show");
        offcanvasOverlay.fadeIn(300);
      }
    });

    menu.on("click", function () {
      let ws = $(window).width();
      if (offcanvas.hasClass("show")) {
        body.removeAttr("style");
        offcanvas.removeClass("show");
        offcanvasOverlay.fadeOut(300);
        offcanvasBody.fadeOut(150);
      } else {
        if (ws < 1200) {
          body.css({ overflowY: "hidden" });
          offcanvasBody.fadeIn(150);
          offcanvas.addClass("show");
          offcanvasOverlay.fadeIn(300);
        }
      }
    });

    $(window).on("resize", function () {
      let ws = $(window).width();
      if (ws > 1200) {
        if (offcanvas.hasClass("show")) {
          body.removeAttr("style");
          offcanvas.removeClass("show");
        }

        offcanvasOverlay.css({ display: "none" });
        offcanvasBody.css({ display: "block" });
      } else {
        if (!offcanvas.hasClass("show")) {
          offcanvasBody.css({ display: "none" });
        }
      }
    });
  },

  init() {
    this.floatingMenu();
    this.toggleMenu();
  },
});
