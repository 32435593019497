import * as $ from "jquery";
import animating from "./libs/animating.js";
import header from "./libs/header.js";
import popup from "./libs/popup.js";
import responsiveImage from "./libs/responsive-images.js";
import {
  gsap,
  TweenLite,
  TweenMax,
  MotionPathPlugin,
  ScrollToPlugin,
  ScrollTrigger,
  Linear,
  Power2,
} from "gsap/all";
gsap.registerPlugin(
  TweenLite,
  TweenMax,
  MotionPathPlugin,
  ScrollToPlugin,
  ScrollTrigger
);

const app = {};
const animatingElement = $(".animating-element");

if (window.innerWidth > 1280) {
  animating($, gsap).from({
    element: animatingElement,
    from: "from-top", //animation type 'from-top','from-bottom','from-left','from-right','fade','swipe-horizontal','swipe-vertical'
    time: 1, // time in secnd
    ease: "power3.easeOut", // easeing https://greensock.com/docs/v3/Eases
  });
}

app.menuAnchor = function () {
  const menuAnchor = document.querySelector(".navbar");
  const menus = document.querySelectorAll(".navbar-nav .nav-item a");

  menus.forEach((menu) => {
    menu.addEventListener("click", () => {
      menu.parentElement.classList.remove("active");
      let target = document.querySelector("." + menu.dataset.target);
      let offsetTop = target.offsetTop - 125;

      gsap.to($(window), 1.2, {
        scrollTo: {
          y: offsetTop,
        },
        ease: "Power2.easeOut",
        overwrite: 1,
      });
    });
  });

  window.addEventListener("scroll", () => {
    const sections = document.querySelectorAll(".anchor");

    sections.forEach((section, is) => {
      if (section.offsetTop - 150 <= window.scrollY) {
        menus.forEach((menu, im) => {
          if (is === im) {
            menu.parentElement.classList.add("active");
          } else {
            menu.parentElement.classList.remove("active");
          }
        });
      }
    });
  });
};

app.init = function () {
  this.menuAnchor();
};

app.init();

$(document).ready(() => {
  header($).init();
  popup($).closePopup();

  responsiveImage($).init($(".swiper-slide > img"), {
    type: "image", //type image = <img>  or bg = background: url(...)
  });
});

window.openPopup = (id) => {
  popup($).openPopup(id);
};
window.closePopup = (id) => {
  popup($).closePopup(id);
};
window.closePopupById = (id) => {
  popup($).closePopupById(id);
};
