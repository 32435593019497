import { gsap } from "gsap";

export default ($) => ({
  openPopup(id) {
    let elem = $(".popup" + id);
    let contentPop = elem.find(".popup-content");

    $("body").addClass("lock");

    gsap.set(elem, {
      opacity: "0",
      scale: "1",
    });

    gsap.set(contentPop, {
      opacity: "0",
      scale: "1.1",
    });

    gsap.to(elem, 0.4, {
      css: {
        display: "flex",
        opacity: "1",
        scale: "1",
      },
      delay: 0,
      ease: "power2.out",
    });

    gsap.to(contentPop, 0.3, {
      css: {
        opacity: "1",
        scale: "1",
      },
      delay: 0,
      ease: "power2.out",
    });
  },

  closePopup() {
    $(".overlay, .popup-close").on("click", function (event) {
      let elem = $(this).parents(".popup");
      let contentPop = elem.find(".popup-content");
      gsap.to(elem, 0.4, {
        css: {
          display: "none",
          opacity: "0",
          scale: "1",
        },
        delay: 0,
        ease: "power2.out",
      });

      gsap.to(contentPop, 0.3, {
        css: {
          opacity: "0",
          scale: "1.1",
        },
        delay: 0,
        ease: "power2.out",
      });

      $("body").removeClass("lock");
      return false;
    });
  },

  closePopupById(id) {
    let elem = $(id);
    let contentPop = elem.find(".popup-content");
    gsap.to(elem, 0.4, {
      css: {
        display: "none",
        opacity: "0",
        scale: "1",
      },
      delay: 0,
      ease: "power2.out",
    });

    gsap.to(contentPop, 0.3, {
      css: {
        opacity: "0",
        scale: "1.1",
      },
      delay: 0,
      ease: "power2.out",
    });

    $("body").removeClass("lock");
    return false;
  },

  init() {
    // this.closePopup()
  },
});
